<template>
 <ProductCard v-if="data" style="margin-bottom: 30px; height:100%">
  <sdModal :onCancel="handlePriceModalCancel" :visible="priceModalVisible" title="" type="primary">
   <ModalMain>
    <ProductPriceSolarInfoCard :data="data" :orderFlow="true" :priceSummary="true"/>
   </ModalMain>
  </sdModal>
  <figure style="text-align: center">
   <img
    v-if="data && data.product && data.product.imageFileURL"
    :src="data.product.imageFileURL"
    alt=""
    style="padding:20px 20px 0px 20px; max-height: 200px;width:auto;max-width:250px"
   />
   <img v-else
        :src="require('@/static/img/avatar/profileImage.png')"
        alt=""
   />
  </figure>
  <figcaption style="padding: 20px 20px 0 20px">
   <sdHeading as="h5" class="product-single-title" style="text-align:center;margin-bottom: 10px;font-weight: bold">
    {{ data.product.name }}
   </sdHeading>
   <a-row v-if="!data.product.isProxy" class="mt-5 mb-5">
    <a-col :xs="24">
     <div style="color:#000000">Terugverdientijd:
      {{
       data.paybackPeriod.years > 0 ? `${data.paybackPeriod.years} jaar` : ''
      }}{{ data.paybackPeriod.months > 0 ? ` en ${data.paybackPeriod.months} maanden` : '' }}
     </div>
    </a-col>
    <a-col :xs="24">
     <div style="color:#000000">Vermogen:
      {{
       parseInt(getPanelAttribute(data.calculation, 'panel', 'count')) * parseInt(getPanelAttribute(data.calculation, 'panel', 'wattPeak'))
      }} Wp
     </div>
    </a-col>
    <a-col :xs="24">
     <div style="color:#000000">Opbrengst eerste jaar: {{ data.wp1Year }} kWh</div>
    </a-col>
    <a-col :xs="24">
     <div style="color:#000000">Huidige energielabel:
      <span v-if="energyLabel">
          <span :style="`background-color:${getEnergyLabelColor(energyLabel)}`"
                class="energy-label">{{ energyLabel }}</span>
          <span :style="`border-left: 11px solid ${getEnergyLabelColor(energyLabel)}`"
                class="energy-label_arrow"></span>
         </span>
      <span v-else>niet beschikbaar</span>
     </div>
    </a-col>
   </a-row>
   <a-row v-else-if="data.product.isProxy" class="mt-5 mb-5">
    <a-row>
     <a-col :xs="24">
      <p style="text-align: start">
          <span v-if="data.product.uspText"
                class="uspText" v-html="data.product.uspText"></span>
      </p>
     </a-col>
    </a-row>
   </a-row>
   <sdHeading as="h6" class="product-single-title pb-5" style="text-align: center;margin-bottom: 30px;font-size:14px">
    <div class="quantity-wrapper mt-2 mb-2">
     <a-select :allowClear="true" :loading="loadingFetchSolarPackageData"
               :value="getPanelAttribute(data.calculation, 'panel', 'count')" class="sDash_fullwidth-select"
               size="large" @select="setNewPanelCountForProduct(data.product.id,$event)">
      <a-select-option v-for="(item,index) in panelValues" :key="index" :value="item.value"
                       name="panelCount">
       {{ item.key }}
      </a-select-option>
     </a-select>
    </div>
    <div v-if="!data.product.isProxy" class="pt-5 pb-10" style="width:100%">
     <InfoCircleOutlined
      style="font-size: 16px;padding-right:5px"
      @click.prevent="showPriceModal"/>
     <span
      style="font-size: 1.0rem;"><strong>{{
       `${new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(data.calculation.total.exVat)}`
      }}</strong>
    </span>
    </div>
   </sdHeading>
   <div class="product-single-action"
        style="position: absolute;right: 0;bottom: 0;padding: 0;margin: 0;display:flex;width:100%">
    <sdButton :disabled="loadingFetchSolarPackageData" class="btn-cart"
              size="small"
              style="width:100% !important;" type="primary"
              @click="handleSelectProduct(data)">
     <span>Selecteren</span>
    </sdButton>
   </div>
  </figcaption>
 </ProductCard>
</template>
<script>
import {ProductCard} from '../Style';
import VueTypes from 'vue-types';
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {InfoCircleOutlined} from "@ant-design/icons-vue";
import ProductPriceSolarInfoCard from "@/view/orders/component/ProductPriceSolarInfoCard";
import {getEnergyLabelColor} from "@/utility/utility";
import {getPanelAttribute} from "@/utility/solarUtility";

const ProductCardSolarWithPanelCount = defineComponent({
 name: 'ProductCardSolarWithPanelCount',
 components: {ProductPriceSolarInfoCard, ProductCard, InfoCircleOutlined},
 props: {
  data: VueTypes.object.def(null),
  surfaces: VueTypes.array.def([]),
  panelValues: VueTypes.array.def([]),
  roofType: VueTypes.string.def(''),
  discount: VueTypes.number.def(0),
  maxPanels: VueTypes.number.def(0),
  minPanels: VueTypes.number.def(0),
  type: VueTypes.oneOf(['CONSUMER', 'BUSINESS']).def('CONSUMER'),
  energyLabel: VueTypes.string.def(''),
 },
 methods: {
  handleSelectProduct(data) {
   this.$emit('handle-product-selection', data)
  },
 },
 setup(props) {
  const {dispatch, state} = useStore();
  const loadingFetchSolarPackageData = computed(() => state.solar.loadingFetchSolarPackageData);

  const {matched} = useRoute();

  const priceModalVisible = ref(false)
  const showPriceModal = async () => {
   priceModalVisible.value = true;
  }

  const handlePriceModalCancel = () => {
   priceModalVisible.value = false;
  }


  const setNewPanelCountForProduct = (productId, panelCount) => {
   dispatch('fetchSolarPackage', {
    productId: productId,
    surfaces: props.surfaces,
    panelCount: panelCount,
    roofType: props.roofType,
    discount: props.discount && props.discount !== '' ? parseFloat(props.discount) : 0,
   });
  }

  return {
   dispatch,
   loadingFetchSolarPackageData,
   matched,
   handlePriceModalCancel,
   priceModalVisible,
   showPriceModal,
   setNewPanelCountForProduct,
   getEnergyLabelColor,
   getPanelAttribute,
  };
 },
});

export default ProductCardSolarWithPanelCount;
</script>
<style lang="scss" scoped>
//.product-single-action {
// margin: 5px -5px -5px -5px !important;
//}

.btn-inc:hover i, .btn-inc:hover svg, .btn-dec:hover i, .btn-dec:hover svg {
 color: #ffffff;
}

.cart-single-quantity button {
 padding: 0 !important;
 background-color: rgb(244, 245, 247);

 :hover {
  color: #ffffff !important
 }
}
</style>
